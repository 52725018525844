import styled from "styled-components";
import { useThemeColors } from "../../providers/ThemeProvider/ThemeProvider";

export function Open() {
  const themeColors = useThemeColors();

  return (
    <NavIcons>
      <svg
        width="36"
        height="16"
        viewBox="0 0 36 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 1.3335H34.5M1.5 8.00016H34.5M1.5 14.6668H34.5"
          stroke={themeColors.NAV_ICONS}
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </NavIcons>
  );
}

export function Close() {
  const themeColors = useThemeColors();
  return (
    <NavIcons>
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 17L23 1M1 1L23 17"
          stroke={themeColors.NAV_ICONS}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </NavIcons>
  );
}

export function LigthMode() {
  return (
    <NavIcons>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.03107 12.42C2.39107 17.57 6.76107 21.76 11.9911 21.99C15.6811 22.15 18.9811 20.43 20.9611 17.72C21.7811 16.61 21.3411 15.87 19.9711 16.12C19.3011 16.24 18.6111 16.29 17.8911 16.26C13.0011 16.06 9.00107 11.97 8.98107 7.13996C8.97107 5.83996 9.24107 4.60996 9.73107 3.48996C10.2711 2.24996 9.62107 1.65996 8.37107 2.18996C4.41107 3.85996 1.70107 7.84996 2.03107 12.42Z"
          stroke="#00070B"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </NavIcons>
  );
}

export function DarkMode() {
  return (
    <NavIcons>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 18.5C13.7239 18.5 15.3772 17.8152 16.5962 16.5962C17.8152 15.3772 18.5 13.7239 18.5 12C18.5 10.2761 17.8152 8.62279 16.5962 7.40381C15.3772 6.18482 13.7239 5.5 12 5.5C10.2761 5.5 8.62279 6.18482 7.40381 7.40381C6.18482 8.62279 5.5 10.2761 5.5 12C5.5 13.7239 6.18482 15.3772 7.40381 16.5962C8.62279 17.8152 10.2761 18.5 12 18.5Z"
          stroke="#FAC515"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.14 19.14L19.01 19.01M19.01 4.99L19.14 4.86M4.86 19.14L4.99 19.01M12 2.08V2M12 22V21.92M2.08 12H2M22 12H21.92M4.99 4.99L4.86 4.86"
          stroke="#FAC515"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </NavIcons>
  );
}

export function Lang() {
  const themeColors = useThemeColors();
  return (
    <NavIcons>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
          stroke={themeColors.NAV_ICONS}
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.00154 3H9.00154C7.05157 8.84157 7.05157 15.1584 9.00154 21H8.00154M15.0015 3C16.9515 8.84157 16.9515 15.1584 15.0015 21"
          stroke={themeColors.NAV_ICONS}
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3 15.9996V14.9996C8.84157 16.9496 15.1584 16.9496 21 14.9996V15.9996M3 8.99959C8.84157 7.04962 15.1584 7.04962 21 8.99959"
          stroke={themeColors.NAV_ICONS}
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </NavIcons>
  );
}

export function FwdArrow() {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0263 0.941895L15.0846 6.00023L10.0263 11.0586M0.917969 6.00023H14.943"
        stroke="white"
        stroke-width="1.6"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function UpArrow() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9987 53.6663V2.33301M27.9987 2.33301L2.33203 27.9997M27.9987 2.33301L53.6654 27.9997"
        stroke="#039EF2"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

interface BodyProps {
  open?: boolean;
  stroke?: string;
  backgroundColor?: string;
}

const NavIcons = styled.div<BodyProps>`
  stroke: ${({ stroke }) => stroke};
`;
